<template>
  <f-page :title="$t('labels.companies')">
    <f-card style="max-width: 50%">
      <f-datatable
        ref="companiesDatatable"
        :columns="companyColumns"
        data-url="/companies"
        :features="{ crud: true, actionColumnFirst: false }"
        :options="{
          reorderableRows: true,
          useRowAttrFunc: true,
          onReorderRowsDrag: onDrag,
          onReorderRowsDrop: onDrop,
          rowStyle: passiveCompanyRowStyle
        }"
        @create="onClickCreateCompany"
        @delete="onClickDeleteCompany"
        @update="onClickUpdateCompany"
      />
    </f-card>
    <f-modal
      ref="modalCompanyEdit"
      size="lg"
      :title="$t('labels.company')"
      @submit="saveCompany"
    >
      <template
        v-if="selectedCompany.id"
        slot="header-right"
      >
        <f-user-edit-info
          v-if="selectedCompany"
          :data="selectedCompany"
        />
      </template>
      <modal-company-edit :company="selectedCompany" />
    </f-modal>
  </f-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { companyColumns } from '@/data/columns'
import ModalCompanyEdit from './ModalCompanyEdit'


export default {
  components: {
    ModalCompanyEdit
  },
  data() {
    return { companyColumns, selectedCompany: {}, reOrder: {} }
  },
  computed: {
    ...mapGetters([
      'user',
      'selectedProjectId',
      'selectedCompanyId'
    ]),
  },
  methods: {
    ...mapActions([
      'logout',
      'setProject',
      'setCompany',
      'updateAdminCompany'
    ]),
    async saveCompany() {
      if (this.selectedCompany.id) {
        await this.$http.put(
          `companies/${this.selectedCompany.id}`,
          this.selectedCompany
        )
        this.$refs.modalCompanyEdit.hide()
        this.checkUserStoreCompany()
      } else {
        await this.$http.post('/companies', this.selectedCompany)
        const companies = await this.$http.get('/companies')

        if (companies.count === 1) {
          this.logout()
        }

        this.$refs.modalCompanyEdit.hide()
      }

      this.$refs.companiesDatatable.refresh()
    },
    onClickCreateCompany() {
      this.selectedCompany = { projects: [], isActive: true }
      this.$refs.modalCompanyEdit.show()
    },
    async onClickUpdateCompany(row) {
      this.selectedCompany = await this.$http.get(`/companies/${row.id}`)
      this.$refs.modalCompanyEdit.show()
    },
    async onClickDeleteCompany(row) {
      await this.$http.delete(`/companies/${row.id}`)
      const companies = await this.$http.get('/companies')

      if (companies.count === 0) {
        this.logout()
      }

      this.$refs.companiesDatatable.refresh()
    },
    onDrag({ id, order }) {
      this.reOrder.previousOrder = order
      this.reOrder.orderId = id
    },
    async onDrop({ order }) {
      this.reOrder.newOrder = order

      try {
        await this.$http.put(`/companies/${this.reOrder.orderId}/orders`,
          this.reOrder
        )
      } finally {
        this.$refs.companiesDatatable.refresh()
      }
    },
    async checkUserStoreCompany() {
      if (this.user.company.id === this.selectedCompany.id && !this.selectedCompany.isActive) {
        const companies = await this.$http.get('/companies/withoutCount')

        this.updateAdminCompany({
          companyId: companies[0].id,
          slug: companies[0].slug,
          name: companies[0].name
        })
        this.setCompany(companies[0].id)

        this.$store.dispatch('setProject', companies[0].projects[0].id)
      }
    },
    passiveCompanyRowStyle(row) {
      return !row.isActive && {
        css: {
          'background-color': 'pink'
        }
      }
    }
  }
}
</script>
