<template>
  <div>
    <f-col
      class="required px-0"
      md="12"
    >
      <f-input
        v-model="company.name"
        :label="$t('labels.companyName')"
        rules="required"
      />
    </f-col>
    <f-switch
      v-model="company.isActive"
      :false-label="$t('labels.passive')"
      :true-label="$t('labels.active')"
    />
    <h4>
      {{ $t('labels.ownProjects') }}
    </h4>

    <f-datatable
      ref="projectsDatatable"
      :columns="projectColumns"
      :data="company.projects"
      :features="{ crud: true,}"
      :options="{
        reorderableRows: true,
        useRowAttrFunc: true,
        onReorderRowsDrag: onDrag,
        onReorderRowsDrop: onDrop,
        rowStyle: passiveProjectRowStyle
      }"
      @create="onClickCreateProject"
      @delete="onClickDeleteProject"
      @update="onClickUpdateProject"
    />
    <f-modal
      ref="modalProjectEdit"
      scrollable
      size="md"
      :title="$t('labels.project')"
      @submit="saveProject"
    >
      <template slot="header-right">
        <f-user-edit-info />
      </template>
      <modal-project-edit :project="selectedProject" />
    </f-modal>
  </div>
</template>

<script>
import ModalProjectEdit from './ModalProjectEdit'
import { projectColumns } from '@/data/columns'
import { generateId } from '@/utils'

export default {
  components: { ModalProjectEdit },
  props: {
    company: { type: Object }
  },
  data() {
    return {
      projectColumns,
      selectedProject: {},
      projectIndex: null,
      reOrder: {}
    }
  },
  methods: {
    saveProject() {
      if (this.projectIndex !== null) {
        this.company.projects[this.projectIndex] = {
          ...this.company.projects[this.projectIndex],
          ...this.selectedProject
        }
      } else {
        this.company.projects.push({
          tempId: generateId(),
          order: this.findNewMaxOrder(),
          ...this.selectedProject,
        })
      }

      this.company.projects = this.company.projects.sort((a, b) => a.order - b.order)

      this.projectIndex = null
      this.$refs.modalProjectEdit.hide()
      this.selectedProject = {}

      this.$refs.projectsDatatable.refresh()
    },
    onClickCreateProject() {
      this.selectedProject = { isActive: true }
      this.$refs.modalProjectEdit.show()
    },
    async onClickUpdateProject(row) {
      this.projectIndex = this.company.projects.findIndex(
        project => project.id === row.id || project.tempId === row.tempId
      )
      this.selectedProject = row
      this.$refs.modalProjectEdit.show()
    },
    onClickDeleteProject(row) {
      for (const p of this.company.projects) {
        if (row.order < p.order) {
          p.order -= 1
        }
      }

      this.company.projects = this.company.projects.filter(
        project => project.id !== row.id || project.tempId !== row.tempId
      )

      this.projectIndex = null
    },
    onDrag({ id, order, tempId }) {
      this.reOrder.previousOrder = order
      this.reOrder.orderId = tempId || id
    },
    onDrop({ id, tempId, order }) {
      const { projects } = this.company;
      const { orderId, previousOrder } = this.reOrder;

      this.reOrder.newOrder = order;

      const oldOrderIndex = projects.findIndex(
        p => p.id === orderId || p.tempId === orderId
      );

      const isMovingUp = this.reOrder.newOrder < previousOrder;

      for (const project of projects) {
        const { order: projectOrder } = project;

        if (isMovingUp) {
          if (projectOrder >= this.reOrder.newOrder && projectOrder < previousOrder) {
            project.order += 1;
          }
        } else {
          if (projectOrder > previousOrder && projectOrder <= this.reOrder.newOrder) {
            project.order -= 1;
          }
        }
      }

      projects[oldOrderIndex].order = this.reOrder.newOrder;

      this.company.projects = projects.sort((a, b) => a.order - b.order);

      this.$refs.projectsDatatable.refresh();
    },
    findNewMaxOrder() {
      if (this.company.projects.length === 0) {
        return 1
      }

      const maxOrder = Math.max(...this.company.projects.map(p => p.order))

      return maxOrder + 1
    },
    passiveProjectRowStyle(row, index) {
      return !row.isActive && {
        css: { 'background-color': 'pink' }
      }
    }

  }
}
</script>

<style></style>
