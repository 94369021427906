<template>
  <f-row>
    <f-col
      class="required"
      md="12"
    >
      <f-input
        v-model="project.name"
        :label="$t('labels.projectName')"
        rules="required"
      />
    </f-col>
    <f-col
      class="required d-flex justify-content-end"
      md="12"
    >
      <f-switch
        v-model="project.isActive"
        :false-label="$t('labels.passive')"
        rules="required"
        :true-label="$t('labels.active')"
      />
    </f-col>
  </f-row>
</template>

<script>
export default {
  props: { project: { type: Object } }
}
</script>

<style></style>
